import logo from './logo.svg';
import './App.css';
import Header from "./header/header";
import {constants} from "./constants/constants";
import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./home/home";
import About from "./about/about";
import Service from "./service/service";
import Footer from "./footer/footer";
import Price from "./price/price";


function App() {
  return (
    <div>
      <nav>
        <Header/>
      </nav>
      <main>
        <Routes>
            <Route path={constants.homeRoute} element={<Home/>}/>
            <Route path={constants.aboutRoute} element = {<About/>}/>
            <Route path={constants.serviceRoute} element = {<Service/>}/>
            <Route path={constants.priceRoute} element = {<Price/>}/>
            <Route path="*" element={<Navigate to={constants.homeRoute}/>} />


        </Routes>
      </main>
        <Footer/>
    </div>
  );
}

export default App;
