import "./service.scss";
import React from "react";
import lash3 from "../images/lash3.jpg";
import classic from "../images/classic.jpg";
import D9 from "../images/9D.jpg";
import catEye from "../images/catEye.png";
import nature from "../images/Nature.png";
import henna from "../images/henna.png";
import nano from "../images/nano.png";
import lamination from "../images/lamination.png";
import french from "../images/french.png";
// import french from "../images/nano.png";
import lash9 from "../images/lash9.jpg";

const Service=()=>{

    return(
        <div className={"service-container"}>
            <div className={"service-title"}>
                Eyelash Extensions
            </div>
            <div className={"service-row"}>
                <div className={"service-left"}>
                    <img src={catEye}/>
                </div>
                <div className={"service-right"}>
                    <div className={"service-right-title"}>
                        Cat Eye Shape
                    </div>
                    <div className={"service-right-content"}>
                        The Cat Eye application technique relies on shorter extensions across the length of the eye, gradually becoming longer toward the outer corners. That gradual increase of length from inner to outer eye provides a more exotic aesthetic that is mysteriously attractive.
                    </div>
                </div>
            </div>

            <div className={"service-row"}>
                <div className={"service-left"}>
                    <img src={nature}/>
                </div>
                <div className={"service-right"}>
                    <div className={"service-right-title"}>
                        Doll Eye Shape
                    </div>
                    <div className={"service-right-content"}>
                        When we work with the Doll eye shape of your eye, that means using shorter extensions at the inner points of the eye, and  longer lashes extensions as we work toward the middle and outer part of the eye, where the lashes are once again shorter. This technique provides that pleasing almond shape that so many people prize, and helps to reinforce an elegant and natural lash look.
                    </div>
                </div>
            </div>

            <div className={"service-title"}>
                Eyebrow
            </div>
            <div className={"service-row"}>
                <div className={"service-left"}>
                    <img src={henna}/>
                </div>
                <div className={"service-right"}>
                    <div className={"service-right-title"}>
                        Henna eyebrows
                    </div>
                    <div className={"service-right-content"}>
                        Henna has been used for hundreds of years as a natural dye for hair and skin. Its properties aim to temporarily freshen up your brow color keeping you on top of your brow game without undergoing an invasive treatment.
                        Dying both the skin and the brow hair also creates an illusion of depth for a 3D effect.
                    </div>
                </div>
            </div>
            <div className={"service-row"}>
                <div className={"service-left"}>
                    <img src={lamination}/>
                </div>
                <div className={"service-right"}>
                    <div className={"service-right-title"}>
                        Eyebrow Lamination
                    </div>
                    <div className={"service-right-content"}>
                        The latest trend in eyebrow beauty Eyebrow Lamination is a keratin treatment that turns messy, unruly brows into perfectly shaped, fuller Insta-worthy brows for up to two months.
                    </div>
                </div>
            </div>
            <div className={"service-row"}>
                <div className={"service-left"}>
                    <img src={nano}/>
                </div>
                <div className={"service-right"}>
                    <div className={"service-right-title"}>
                        NANO Ombré POWDER EYEBROWS
                    </div>
                    <div className={"service-right-content"}>
                        Ombré  Powder Eyebrows or Microshading, is a semi-permanent makeup.  The powder look is created by using the shading technique to the brow to perfect their shape and create an airbrushed/powdered effect that starts light at the bulb and gradually becomes darker at the tail.  Ombré Powder Brows are great for any skin type.
                        Your eyebrow will look fuller, more defined as well as dramatic.
                    </div>
                </div>
            </div>

            <div className={"service-title"}>
                Nails
            </div>
            <div className={"service-row"}>
                <div className={"service-left"}>
                    <img src={french}/>
                </div>
                <div className={"service-right"}>
                    <div className={"service-right-title"}>
                        Bio gel French
                    </div>
                    <div className={"service-right-content"}>
                        Bio gel Nails   You have 2 options when getting started with Bio Gel Nails. For added length & strength, choose a Full Set. If you like the length of your natural nails, but wish they were more resistant to breaking, choose an Overlay. Our Full Sets and Natural Nail Overlays include Shellac polish application.
                    </div>
                </div>
            </div>

        </div>
    )
}
export default Service;