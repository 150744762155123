import {combineReducers} from "redux";


export const rootReducers=combineReducers({
    // products:productsReducer,
    // auth:authReducer,
    // user: userReducer,
    // userInfo:userInfoReducer,
    // cart:cartReducer,
    // tempCart:tempCartReducer,
    // shipping:shippingReducer,
    // orders:ordersReducer,
    // filter:filterReducer,
    // reviews:reviewsReducer,
    // summary:summaryReducer,

})