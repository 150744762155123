import React from "react";
import "./ThreeColumnPerRow.scss"

const ThreeColumnPerRow=(props)=>{
    return(
        <div className={"tio"}>
            <div className={"tio-row"}>
                <div className={"tio-title"}>
                    {props.Column1title}
                </div>
                <div className={"tio-content"}>
                    {props.Column1content}
                </div>
            </div>
            <div className={"tio-row"}>
                <div className={"tio-title"}>
                    {props.Column2title}
                </div>
                <div className={"tio-content"}>
                    {props.Column2content}
                </div>
            </div>
            <div className={"tio-row"}>
                <div className={"tio-title"}>
                    {props.Column3title}
                </div>
                <div className={"tio-content"}>
                    {props.Column3content}
                </div>
            </div>
        </div>
    )
}
export default ThreeColumnPerRow;