import React from "react";
import lash3 from "../images/lash3.jpg";
import "./about.scss"

const About=()=>{
    return(
        <div className={"about-container"}>
            <div className={"about-left"}>
                <text className={"title"}>Get In Touch</text>
                <text>647-860-3880</text>
                <text>veyelashbrow@gmail.com</text>
                <text>Facebook: V Eyelash Brow</text>
                <text> Instagram: Veyelashbrow</text>
                <text>158 Eglinton Avenue </text>
                <text>West Toronto (near yonge)</text>



            </div>
            <div className={"about-right"}>
                <img src={lash3}/>
            </div>
        </div>
    )
}

export default About;