import React from "react";
import lash1 from "../images/lash1.jpg"
import lash2 from "../images/lash2.jpg"
import lash3 from "../images/lash3.jpg"
import lash4 from "../images/lash4.jpg"
import lash5 from "../images/lash5.jpg"
import lash6 from "../images/lash6.jpg"
import lash7 from "../images/lash7.jpg"
import lash8 from "../images/lash8.jpg"
import lash10 from "../images/lash10.jpg"
import nail1 from "../images/nail1.jpg"
import nail2 from "../images/nail2.jpg"
import nail3 from "../images/nail3.jpg"
import nail4 from "../images/nail4.jpg"
import nail5 from "../images/nail5.jpg"
import nano from "../images/nano.png";
import mainImg from "../images/home_main.png";
import "./home.scss";
import ThreeColumnPerRow from "../conponents/ThreeColumnPerRow/ThreeColumnPerRow";

const Home=()=>{
    const midColumn1title="Beauty & Lifestyle"
    const midColumn1content="Longer lashes that provide that soft, fluttery lash look long associated with ‘perfect lashes’. Thicker lashes and the type of volume that mascara can only pretend to imitate. Perfectly-curled lashes that help to open up your eyes, creating the illusion of bigger, brighter eyes"
    const midColumn2title="Save Time"
    const midColumn2content="you’ll finally be able to cut back on your use of mascara and other makeup products, since your extensions will do that mascara’s job for it. Naturally, that reduced reliance on makeup will provide another important benefit too: more free time to do the things you love."
    const midColumn3title="Self-Confidence"
    const midColumn3content="A more youthful appearance, as your new lashes help to focus attention on one of your most youthful features: the eyes. Unlike ordinary makeup, eyelash extensions will make you Look gorgeous as soon as you wake up, in rainy or snowy weather, while traveling, in me pool and at the gym."
    return(
        <div className={"home-container"}>
            <div className={"home-top"} >
                <div className={"home-top-left"}>
                    <img src={lash6}/>
                </div>
                <div className={"home-top-right"}>
                    <div className={"title"}>
                        <text>The Best Eyelash Extensions in Toronto</text>
                        {/*<text>Ensure You Always Look Your Best! </text>*/}

                    </div>
                    <div className={"content"}>
                        We know that eyelash applications should be stress-free, so we’ve worked hard to create an environment at salons that encourages customers to relax and unwind from the cares of the day. To that end, our focus is on maintaining that calm and peaceful ambiance that every client needs to feel right at home.
                    </div>
                </div>
            </div>
            <div className={"home-mid1"}>
                <div className={"home-mid-title"}>
                    Advantages of Lash Extensions
                </div>
                <ThreeColumnPerRow Column1title={midColumn1title}
                                   Column2title={midColumn2title}
                                   Column3title={midColumn3title}
                                   Column1content={midColumn1content}
                                   Column2content={midColumn2content}
                                   Column3content={midColumn3content}
                />
            </div>
            <div className={"home-mid"}>
                <img src={nail1}/>
                <img src={nail2}/>
                <img src={nail3} className={"hide-element"}/>
            </div>
            <div className={"home-mid"}>
                <img src={lash4}/>
                <img src={lash10}/>
                <img src={lash6} className={"hide-element"}/>
            </div>
            {/*<div className={"home-bottom"}>*/}
            {/*    <div className={"home-bottom-block"}>*/}
            {/*        <div className={"home-bottom-address"}>*/}
            {/*            address*/}
            {/*        </div>*/}
            {/*        <div className={"home-bottom-img"}>*/}
            {/*            <img src={lash3}/>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default Home;