import React from "react";
import "./price.scss"

const Price=()=>{
    return(
        <div className={"price-container"}>
            <div className={"price-row"}>
                <div className={"price-row-title"}>
                    <text style={{fontSize: "30px"}}>Eyelash Extension</text>
                </div>
                <div className={"price-row-content"}>
                    <div className={"price-row-content-left"}>
                        <text>Classic</text>
                        <text>100 eyelashes</text>
                        <text>150 eyelashes</text>
                        <text>200 eyelashes</text>
                        <text>250 eyelashes</text>
                        <text>300 eyelashes</text>
                        <text>6D(hybrid)</text>
                        <text>6D(Volumn)</text>
                        <text>9D(Mega)</text>
                        <text>D cure extra</text>
                    </div>
                    <div className={"price-row-content-right"}>
                        <text style={{opacity:0}}>  1</text>
                        <text>$65</text>
                        <text>$85</text>
                        <text>$110</text>
                        <text>$130</text>
                        <text>$160</text>
                        <text>$150</text>
                        <text>$200</text>
                        <text>$300</text>
                        <text>$10</text>
                    </div>
                </div>
            </div>

            <div className={"price-row"}>
                <div className={"price-row-title"}>
                    <text style={{fontSize: "30px"}}>Eyebrow service</text>
                </div>
                <div className={"price-row-content"}>
                    <div className={"price-row-content-left"}>
                        <text>Treading Eyebrow</text>
                        <text>Tinting with Shape</text>
                        <text>Henna for Eyebrow</text>
                        <text>Nano ombré powder eyebrows </text>
                        <text style={{ fontSize: "11px",color: "gray"}} >With one time Touch up (within 3 month)</text>
                        <text>Touch up between 3-10 months</text>
                        <text>11-18 months</text>
                        <text>18 months up</text>

                    </div>
                    <div className={"price-row-content-right"}>
                        <text>$18</text>
                        <text>$35</text>
                        <text>$50</text>
                        <text>$700</text>
                        <text style={{opacity:0}}>  1</text>
                        <text>$300</text>
                        <text>$400</text>
                        <text>$600</text>
                    </div>
                </div>
            </div>


            <div className={"price-row"}>
                <div className={"price-row-title"}>
                    <text style={{fontSize: "30px"}}>Waxing service</text>
                </div>
                <div className={"price-row-content"}>
                    <div className={"price-row-content-left"}>
                        <text>Eyebrow</text>
                        <text>Upper Lip</text>
                        <text>Chin</text>
                        <text>Whole Face</text>
                        <text>Half Arm</text>
                        <text>Full Arm</text>
                        <text>Half leg</text>
                        <text>Full leg</text>
                        <text>Under arm</text>
                        <text>Bikini</text>
                        <text>Brazilian</text>
                        <text>Belly</text>
                    </div>
                    <div className={"price-row-content-right"}>
                        <text>$15</text>
                        <text>$10</text>
                        <text>$10</text>
                        <text>$35</text>
                        <text>$25</text>
                        <text>$35</text>
                        <text>$25</text>
                        <text>$45</text>
                        <text>$15</text>
                        <text>$25</text>
                        <text>$45</text>
                        <text>$10 UP</text>
                    </div>
                </div>
            </div>

            <div className={"price-row"}>
                <div className={"price-row-title"}>
                    <text style={{fontSize: "30px"}}>Nail service</text>
                </div>
                <div className={"price-row-content"}>
                    <div className={"price-row-content-left"}>
                        <text>Manicure</text>
                        <text>Shellac Mani</text>
                        <text>Pedicure</text>
                        <text>Shellac Pedi</text>
                        <text>Spa Pedi</text>
                        <text>Spa Pedi+Shellac</text>
                        <text>Bio gel Extension</text>
                        <text>Bio gel fill</text>

                        <text>Over lay</text>
                        <text>French</text>
                        <text>Art</text>
                        <text>Mani&Pedi</text>
                        <text>Shellac Mani&Pedi</text>
                    </div>
                    <div className={"price-row-content-right"}>
                        <text>$20</text>
                        <text>$35</text>
                        <text>$35</text>
                        <text>$45</text>
                        <text>$45</text>
                        <text>$60</text>
                        <text>$70</text>
                        <text>$55</text>
                        <text>$45</text>
                        <text>$10</text>
                        <text>$10UP</text>
                        <text>$50</text>
                        <text>$80</text>
                    </div>
                </div>
            </div>


            <div className={"price-row"}>
                <div className={"price-row-title"}>
                    <text style={{fontSize: "30px"}}>Skin service</text>
                </div>
                <div className={"price-row-content"}>
                    <div className={"price-row-content-left"}>
                        <text>Facial</text>
                        <text>Deep Clean Facial</text>
                        <text>Fillmed Facial</text>
                        <text>Skin Booster with Fillmed</text>
                        <text>Skin Booster</text>
                        <text>Filler</text>
                    </div>
                    <div className={"price-row-content-right"}>
                        <text>$65</text>
                        <text>$100</text>
                        <text>$200</text>
                        <text>$750</text>
                        <text>$500</text>
                        <text>$750</text>
                    </div>
                </div>
            </div>





        </div>
    )
}
export default Price;
