import './header.scss';
import React from "react";
import {Link} from "react-router-dom";
import {constants} from "../constants/constants";

const tab=["Home","About us","Product"];

const Header=()=>{
    return(
        <div className={"container"}>
            <div className={"header-top"}>
                <text>V EYELASH&BROW</text>
            </div>
            <div className={"header-bottom"}>
                <Link to={constants.homeRoute} >Home</Link>
                <Link to={constants.serviceRoute} >Service</Link>
                <Link to={constants.priceRoute} >Price</Link>
                <Link to={constants.aboutRoute} >About</Link>
            </div>
        </div>
    )
}
export default Header;