import React from "react";
import "./footer.scss"

const Footer=()=>{
    return(
        <div className={"footer-container"}>
            <div className={"footer-content"}>
                <text>158 Eglinton Avenue West Toronto (near yonge)</text>
                <text>647-860-3880 | veyelashbrow@gmail.com</text>
                <text>V Eyelash&Brow</text>
            </div>

        </div>
    )
}
export default Footer;